<template>
  <div class="bg-gray-50 dark:bg-gray-900">
    <div class="flex items-start min-h-screen">
      <div class="my-28 container max-w-6xl px-5 mx-auto">
        <div class="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
          <div class="text-3xl text-gray-800 font-black">用户中心</div>
          <div class="pt-2 pb-8 flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div
                class="pb-10 py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
              >
                <div
                  class="shadow overflow-hidden border-b border-gray-200 rounded"
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50"></thead>
                    <tbody
                      class="bg-gradient-to-r from-green-400 to-blue-500 divide-y divide-gray-200"
                    >
                      <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="flex-shrink-0 h-10 w-10">
                              <img
                                class="h-10 w-10 rounded-full border"
                                :src="avatar"
                                alt=""
                              />
                            </div>
                            <div class="ml-4">
                              <div class="text-sm font-medium text-white">
                                <div>{{ profile.nickname }}</div>
                                <div>{{ profile.username }}</div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <van-cell icon="good-job-o" title="我的点赞" is-link />
              <van-cell icon="chat-o" title="我的留言" is-link />
              <van-cell icon="friends-o" title="我的足迹" is-link />
              <van-cell icon="points" title="我的 Justag" is-link />
              <van-cell icon="like-o" title="关于" is-link />
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-tabbar v-model="active">
      <van-tabbar-item to="/shop" icon="gift-o">商店</van-tabbar-item>
      <van-tabbar-item to="/anlytic" icon="chart-trending-o"
        >分析</van-tabbar-item
      >
      <van-tabbar-item
        to="/me"
        :icon="avatar + '?x-oss-process=image/rounded-corners,r_4096'"
        >我</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
import { userinfo } from "@/api/store";
// let _this = this
export default {
  name: "Anlytic",
  data() {
    return {
      active: 2,
      avatar: "",
      profile: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // 请求信息
      userinfo().then((response) => {
        this.profile = response.data.data;
        this.avatar = response.data.data.avatar;
        console.log(response.data.data);
      });
    },
  },
};
</script>